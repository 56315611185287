<template>
  <div class="p-4">
    <h2 class="mb-5" v-if="$props.title !== ''">{{$props.title}}</h2>
    <div class="icon-row-wrapper d-flex align-items-center justify-content-center position-relative text-center" :style="'background-color:'+$props.json_config.background">
    <!-- Botón Izquierdo -->
    <!--<button
        class="btn btn-primary start-0 top-50 translate-middle-y"
        @click="scrollLeft"
        v-if="canScrollLeft"
    >
      &larr;
    </button>-->

    <b-icon class="text-muted" style="width: 30px; height: 30px;cursor:pointer;" icon="arrow-left-square-fill" @click="scrollLeft" v-if="canScrollLeft"></b-icon>

    <!-- Contenedor de iconos -->
    <div class="icon-row d-flex align-items-center align-content-center justify-content-center overflow-hidden w-100" ref="iconRow" @scroll="updateScrollState">
      <div v-for="(item, index) in $props.json_config.items" :key="index" class="icon-item mx-2">
        <img :src="item.icon" class="icon-img" alt="icon" :style="($props.json_config.max_height?'max-height: '+$props.json_config.max_height+';':'max-height: 100px;')">
      </div>
    </div>

    <!-- Botón Derecho -->
    <!--<button
        class="btn btn-primary end-0 top-50 translate-middle-y"
        @click="scrollRight"
        v-if="canScrollRight"
    >
      &rarr;

    </button>-->
    <b-icon class="text-muted"  style="width: 30px; height: 30px;cursor:pointer;" icon="arrow-right-square-fill" @click="scrollRight"
            v-if="canScrollRight"></b-icon>
  </div>
  </div>
</template>

<script>
export default {
  name: "ImagesCarousel",
  props: {
    title: {
      type: String,
      default: ""
    },
    json_config: {
      type: Object
    }
  },
  data() {
    return {
      icons: [
        "https://cdn.ducksites.systemico.net/cotrasur.ducksites.systemico.net/iso9001.png",
        "https://cdn.ducksites.systemico.net/cotrasur.ducksites.systemico.net/iso14001.png",
        "https://cdn.ducksites.systemico.net/cotrasur.ducksites.systemico.net/iso45001.png",
        "https://cdn.ducksites.systemico.net/cotrasur.ducksites.systemico.net/norsoks006.png",
        "https://cdn.ducksites.systemico.net/cotrasur.ducksites.systemico.net/iqnet.png",
        "https://cdn.ducksites.systemico.net/cotrasur.ducksites.systemico.net/basc.png"
      ],
      canScrollLeft: false,
      canScrollRight: false,
    }
  },
  mounted() {
    this.updateScrollState(); // Inicializa el estado del scroll al cargar
  },
  methods: {
    scrollLeft() {
      const row = this.$refs.iconRow;
      if (row) {
        row.scrollBy({ left: -100, behavior: "smooth" });
        this.updateScrollState();
      }
    },
    scrollRight() {
      const row = this.$refs.iconRow;
      if (row) {
        row.scrollBy({ left: 100, behavior: "smooth" });
        this.updateScrollState();
      }
    },
    updateScrollState() {
      const row = this.$refs.iconRow;
      if (row) {
        this.canScrollLeft = row.scrollLeft > 0;
        this.canScrollRight = row.scrollLeft + row.clientWidth < row.scrollWidth;
      }
    }
  }
};
</script>

<style scoped>
.icon-row-wrapper {
  width: 100%;
  max-width: 97vw; /* Ajusta según tus necesidades */
  margin: 0 auto;
}
.icon-row {
  white-space: nowrap;
  min-width: 100%;
}
.icon-item {
  font-size: 2rem;
}
.icon-img {
  max-height: 100px; /* Ajusta según tus necesidades */
  object-fit: contain;
}
</style>
